<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "Black"
};
</script>

<style lang="scss"></style>
